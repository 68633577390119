import {
  type DtoProgram,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { fromMediaDTO } from '../../../src/utils/api-dto';
import { MediaUtils } from '../../../src/utils/media';

function getProgramEmoji(programType: EnumsProgramType) {
  switch (programType) {
    case EnumsProgramType.ProgramTypeIntros:
      return '👋';
    case EnumsProgramType.ProgramTypeRecognition:
      return '🍦';
    case EnumsProgramType.ProgramTypeBirthdayAndCelebrations:
      return '🎉';
    case EnumsProgramType.ProgramTypeWaterCooler:
      return '💬';
    case EnumsProgramType.ProgramTypeGlobalPairing:
      return '🙌';
    default:
      return null;
  }
}

export function ProgramCard(props: {
  program: DtoProgram;
  onClick?: () => void;
}) {
  const { program, onClick } = props;

  const coverImageUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(program.basicSettings?.cover?.media)
  );

  return (
    <div
      className={`
        w-full min-w-[530px] max-w-[630px] rounded-xl bg-main-layer border border-secondary
        flex justify-between gap-2.5
        ${onClick ? 'cursor-pointer hover:bg-lp-gray-003' : 'cursor-default'}
      `}
      onClick={onClick}
    >
      <div className='flex-1 h-full p-5 flex flex-col items-start gap-2.5'>
        <div className='flex-none font-bold'>
          {getProgramEmoji(program.type)
            ? `${getProgramEmoji(program.type)} `
            : ''}
          {program.name}
        </div>
        <div className='flex-1 text-sms text-icon-gray'>
          {program.basicSettings?.shortDescription}
        </div>
        {onClick && (
          <button
            type='button'
            className='btn flex-none text-3xs font-medium'
          >{`Preview >`}</button>
        )}
      </div>
      <div
        className='relative flex-none w-63 m-2.5 rounded-lg'
        style={{
          aspectRatio: '16/9',
        }}
      >
        <img
          src={coverImageUrl || undefined}
          alt=''
          className='absolute w-full h-full rounded-lg object-cover'
        />
      </div>
    </div>
  );
}

export interface PublicLibraryProgramListProps {
  programs: DtoProgram[];
  onClick?: (program: DtoProgram, index: number) => void;
}

export function PublicLibraryProgramList(props: PublicLibraryProgramListProps) {
  const { programs, onClick } = props;

  return (
    <div className='grid xl:grid-cols-2 gap-5'>
      {programs.map((program, index) => (
        <ProgramCard
          key={program.id}
          program={program}
          onClick={onClick ? () => onClick(program, index) : undefined}
        />
      ))}
    </div>
  );
}

export function PublicLibraryProgramsSection(
  props: PublicLibraryProgramListProps
) {
  const { programs, onClick } = props;

  if (!programs.length) {
    return null;
  }
  return (
    <div className='m-auto px-20 flex flex-col gap-5'>
      <p className='text-xl font-bold'>Daily Program</p>

      <div className='grid xl:grid-cols-2 lp-lg:grid-cols-3 gap-5'>
        {programs.map((program, index) => (
          <ProgramCard
            key={program.id}
            program={program}
            onClick={onClick ? () => onClick(program, index) : undefined}
          />
        ))}
      </div>
    </div>
  );
}
